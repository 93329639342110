@import 'tailwindcss/base';

/* @import "./custom-base-styles.css"; */

@import 'tailwindcss/components';

/* @import "./custom-components.css"; */

@import 'tailwindcss/utilities';

/* @import "./custom-utilities.css"; */

@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Thin.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Light.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  font-display: swap;
  src: url('../../public/fonts/Raleway/Raleway-Black.ttf');
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-xl;
}

/* Following is to remove the up/down arrows in number fields
display: none; <- Crashes Chrome on hover
Apparently some margin are still there even though it's hidden
so we explicitly set it to 0 */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/**
 * Audio Progress Bar Styles
 **/

/* Range Input */
[type='range']#audio-progress-bar {
  --ratio: calc(var(--progress) / var(--duration));
  --sx: calc(
    (theme('spacing.5') / 2) + var(--ratio) * (100% - theme('spacing.5'))
  );

  @apply w-full h-5 p-0 m-0 bg-transparent appearance-none;
}

/* Track */
[type='range']#audio-progress-bar::-webkit-slider-runnable-track {
  /* gradient hack for progress on webkit/chromium browsers */
  background: linear-gradient(
      theme('colors.brand-accent'),
      theme('colors.brand-accent')
    )
    0 / var(--sx) 100% no-repeat theme('colors.brand');
  @apply box-border w-full border-none rounded-full cursor-pointer bg-brand h-1.5;
}
[type='range']#audio-progress-bar::-moz-range-track {
  @apply box-border w-full border-none rounded-full cursor-pointer bg-brand h-1.5;
}
[type='range']#audio-progress-bar::-ms-track {
  @apply box-border w-full border-none rounded-full cursor-pointer bg-brand h-1.5;
}

/* Track Progress */
[type='range']#audio-progress-bar::-moz-range-progress {
  @apply bg-brand-accent h-1.5;
}
[type='range']#audio-progress-bar::-ms-fill-lower {
  @apply bg-brand-accent h-1.5;
}

/* Thumb/Slider */
[type='range']#audio-progress-bar::-webkit-slider-thumb {
  margin-top: calc((theme('spacing[1.5]') - theme('spacing.5')) / 2);
  @apply box-border w-5 h-5 border-none rounded-full appearance-none cursor-pointer bg-brand;
}
[type='range']#audio-progress-bar::-moz-range-thumb {
  @apply box-border w-5 h-5 border-none rounded-full appearance-none cursor-pointer bg-brand;
}
[type='range']#audio-progress-bar::-ms-thumb {
  @apply box-border w-5 h-5 mt-0 border-none rounded-full appearance-none cursor-pointer bg-brand;
}

/* Hide Tooltip */
[type='range']#audio-progress-bar::-ms-tooltip {
  @apply hidden;
}
